var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: true,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        title: _vm.getTitle(),
        visible: _vm.isShowVisible,
        width: _vm.width,
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-card",
        [
          _vm.isShowVisible
            ? _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading",
                    },
                  ],
                  ref: "curMenuRef",
                  attrs: {
                    model: _vm.curMenu,
                    rules: _vm.rules,
                    "label-suffix": ": ",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "name",
                                label: _vm.$t("lang_name_of_the_menu"),
                                prop: "name",
                              },
                            },
                            [
                              _c("lang-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.curMenu.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.curMenu, "name", $$v)
                                  },
                                  expression: "curMenu.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "code",
                                label: _vm.$t("lang_menu_code"),
                                prop: "code",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.curMenu.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.curMenu, "code", $$v)
                                  },
                                  expression: "curMenu.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "parentId",
                                label: _vm.$t("lang_parent_menu"),
                                prop: "parentId",
                              },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  "default-expand-level": 1,
                                  "append-to-body": "",
                                  normalizer: _vm.menuTreeNormalizer,
                                  options: _vm.menuTreeData,
                                  "z-index": 2999,
                                  placeholder: "",
                                },
                                model: {
                                  value: _vm.curMenu.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.curMenu, "parentId", $$v)
                                  },
                                  expression: "curMenu.parentId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "menuType",
                                label: _vm.$t("lang_menu_type"),
                                prop: "menuType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: true, placeholder: "" },
                                  model: {
                                    value: _vm.curMenu.menuType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.curMenu, "menuType", $$v)
                                    },
                                    expression: "curMenu.menuType",
                                  },
                                },
                                _vm._l(_vm.menuTypes, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: _vm.$t(item.name),
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "sort",
                                label: _vm.$t("lang_sort_menu"),
                                prop: "sort",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, precision: 2, step: 1 },
                                model: {
                                  value: _vm.curMenu.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.curMenu, "sort", $$v)
                                  },
                                  expression: "curMenu.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showTenant
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "tenantId",
                                label: "租户列表",
                                prop: "tenantId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.curMenu.tenantId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.curMenu, "tenantId", $$v)
                                    },
                                    expression: "curMenu.tenantId",
                                  },
                                },
                                _vm._l(_vm.tenantList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "route",
                            label: _vm.$t("lang_routing_address"),
                            prop: "route",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.curMenu.route,
                              callback: function ($$v) {
                                _vm.$set(_vm.curMenu, "route", $$v)
                              },
                              expression: "curMenu.route",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "icon",
                                label: "上传图标",
                                prop: "icon",
                              },
                            },
                            [
                              _c("glb-img-upload", {
                                attrs: {
                                  "upload-url": _vm.uploadUrl,
                                  "pub-preview-url": _vm.pubPreviewUrl,
                                  maxUploadSize: 1,
                                },
                                model: {
                                  value: _vm.curMenu.icon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.curMenu, "icon", $$v)
                                  },
                                  expression: "curMenu.icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFormData } },
            [_vm._v(_vm._s(_vm.$t("lang_save_")))]
          ),
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }