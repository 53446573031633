<template>
  <el-dialog
    :center="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :title="getTitle()"
    :visible="isShowVisible"
    :width="width"
    @close="handleCloseDialog"
  >
    <el-card>
      <el-form
        v-if="isShowVisible"
        ref="curMenuRef"
        v-loading="formLoading"
        :model="curMenu"
        :rules="rules"
        label-suffix=": "
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item id="name" :label="$t('lang_name_of_the_menu')" prop="name">
              <lang-input v-model="curMenu.name" clearable></lang-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item id="code" :label="$t('lang_menu_code')" prop="code">
              <el-input v-model="curMenu.code" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item id="parentId" :label="$t('lang_parent_menu')" prop="parentId">
              <treeselect
                v-model="curMenu.parentId"
                :default-expand-level="1"
                append-to-body
                :normalizer="menuTreeNormalizer"
                :options="menuTreeData"
                :z-index="2999"
                placeholder=""
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item id="menuType" :label="$t('lang_menu_type')" prop="menuType">
              <el-select :disabled="true" v-model="curMenu.menuType" placeholder="">
                <el-option :key="item.id" :label="$t(item.name)" :value="item.code" v-for="item in menuTypes"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item id="sort" :label="$t('lang_sort_menu')" prop="sort">
              <el-input-number v-model="curMenu.sort" :min="0" :precision="2" :step="1"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="showTenant">
          <el-form-item id="tenantId" :label="'租户列表'" prop="tenantId">
            <el-select v-model="curMenu.tenantId" placeholder="">
              <el-option :key="item.id" :label="item.name" :value="item.id" v-for="item in tenantList"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item id="route" :label="$t('lang_routing_address')" prop="route">
            <el-input v-model="curMenu.route" clearable></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item id="icon" :label="'上传图标'" prop="icon">
              <glb-img-upload
                v-model="curMenu.icon"
                :upload-url="uploadUrl"
                :pub-preview-url="pubPreviewUrl"
                :maxUploadSize="1"
              ></glb-img-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveFormData">{{ $t('lang_save_') }}</el-button>
      <el-button @click="handleCloseDialog">{{ $t('lang_cancel_') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MenuApi from '@/api/system/MenuApi';
import fileInput from '@/components/file-input/file-input.vue';
import { TenantPage } from '@/api/system/TenantApi';

export default {
  components: { fileInput },
  name: 'MenuForm',
  model: {
    prop: 'curMenu',
    enent: 'change'
  },
  props: {
    showTenant: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: '60vw'
    },

    curMenu: {
      type: Object,
      default() {
        return {};
      }
    },
    menuTypes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    isShowVisible: {
      get: function () {
        return this.visible;
      },
      setter: function () {
        this.updateVisible(false);
      }
    }
  },
  watch: {
    /**
     * 监听弹窗显示隐藏
     * @param val
     */
    isShowVisible: function (val) {
      if (val) {
        this.getMenuTreeData();
        this.getTenantList();
      }
    }
  },
  data() {
    return {
      // 图片组件上传地址
      uploadUrl: '/river-fs/file/create',
      // 图片组件预览地址
      pubPreviewUrl: '/river-fs/file/view',

      tenantList: [],

      menuTreeData: [],
      formLoading: false, //formLoading动画
      rules: {
        name: [{ required: true, message: this.$t('lang_menu_rule_please_fill_in_the_menu_name'), tirgger: 'blur' }],
        code: [{ required: true, message: this.$t('lang_menu_rule_please_fill_in_the_menu_code'), tirgger: 'blur' }],
        menuType: [{ required: true, message: this.$t('lang_menu_rule_please_fill_in_the_menu_type'), tirgger: 'blur' }],
        sort: [{ required: true, type: 'number', message: this.$t('lang_menu_rule_please_fill_in_the_order'), tirgger: 'blur' }],
        parentId: [{ required: true, message: this.$t('lang_menu_rule_please_select_parent_menu'), tirgger: 'blur' }],
        tenantId: [{ required: true, message: '请选择租户', tirgger: 'blur' }],
        route: [{ required: true, message: this.$t('lang_menu_rule_please_fill_in_the_routing_address'), tirgger: 'blur' }]
      },
      menuTreeNormalizer(node) {
        return {
          id: node.id,
          label: this.$t(node.name),
          children: node.children
        };
      }
    };
  },

  created() {},

  methods: {
    // 获取表格数据源
    getMenuTreeData() {
      this.formLoading = true;
      MenuApi.getMenuList(this.searchForm)
        .then(res => {
          if ((res.code = 1)) {
            this.menuTreeData = [{ id: '-1', name: this.$t('lang_top_node'), children: res.data }];
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    /**
     * update visible
     * @param value
     */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    // 获取租户列表
    getTenantList() {
      let params = {
        page: {
          pageIndex: 1,
          pageSize: 999
        }
      };
      TenantPage(params).then(res => {
        if (res.code == '1' && res.data) {
          this.tenantList = res.data.list;
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    /**
     * 关闭弹窗
     */
    handleCloseDialog() {
      this.$emit('update:curMenu', {});
      // 清除表单验证
      this.$refs['curMenuRef'].clearValidate();
      this.updateVisible(false);
      this.$emit('close');
    },

    //保存
    saveFormData() {
      this.$refs['curMenuRef'].validate((valid, object) => {
        if (valid) {
          if (this.curMenu.id) {
            MenuApi.update(this.curMenu)
              .then(res => {
                this.$message.success(res.message);
                this.$emit('close');
              })
              .catch(e => {});
          } else {
            MenuApi.add(this.curMenu)
              .then(res => {
                this.$message.success(res.message);
                this.$emit('close');
              })
              .catch(e => {});
          }
        } else {
          let validArr = [];
          Object.keys(object).forEach(function (key) {
            validArr.push(key);
          });
          let validVal = '#' + validArr[0];
          document.querySelector(validVal).scrollIntoView(true);
        }
      });
    },

    getTitle() {
      if (this.curMenu) {
        if (this.curMenu.id) {
          return this.$t('lang_edit_menu');
        }
      }
      return this.$t('lang_add_menu');
    }
  }
};
</script>

<style scoped>
</style>
