import HttpUtil from '@/utils/HttpUtil';
import { TenantQoModel } from '@/models/system/TenantModel';

export const TenantPage = (params?: TenantQoModel) => {
  var url = '/river-system/admin/tenant/query/page';
  return HttpUtil.post(url, params);
};

export const DeleteTenant = (tenantId: string) => {
  var url = '/river-system/admin/tenant/delete';
  return HttpUtil.get(url, { tenantId: tenantId });
};

export const AccessTenant = (tenantId: string) => {
  var url = '/river-system/admin/tenant/access';
  return HttpUtil.get(url, { tenantId: tenantId });
};

export const SetTenantSysRole = (tenantId: string, roleId: string) => {
  var url = '/river-system/admin/tenant/setTenantRole';
  return HttpUtil.get(url, { tenantId: tenantId, sysRoleId: roleId });
};

// 添加编辑租户
export const tenantAddEdit = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/admin/tenant/save';
  return HttpUtil.post(url, params);
};
